import { queryOptions, type QueryKey } from "@tanstack/react-query";
import type { ReferralAmounts } from "@warrenio/api-spec/spec.oats.gen";
import type { ApiClient } from "../../modules/api/apiClient";
import { getResponseData } from "../../modules/api/apiClient";
import { atomFromStandardQueryOptions } from "../../utils/query/queryAtom.ts";

export const queryKey: QueryKey = ["payment/admin/referral_amounts"];

export function getQuery(client: ApiClient) {
    return queryOptions({
        queryKey,
        queryFn: async ({ signal }): Promise<ReferralAmounts> => {
            return getResponseData(await client.GET("/payment/admin/referral_amounts", { signal }));
        },
    });
}

export const referralAmountsQueryAtom = atomFromStandardQueryOptions(getQuery);
