import type { ReactNode } from "react";
import { WModal, WModalContent } from "../../components/modal/WModal.tsx";
import { TODO } from "../../dev/Todo.tsx";
import { todoAction } from "../../dev/todoSubmit.ts";

export function AddCampaignModal({ button }: { button: ReactNode }) {
    return (
        <WModal button={button}>
            <WModalContent title="Add New Campaign" label="Add" modalAction={todoAction}>
                <TODO />
            </WModalContent>
        </WModal>
    );
}
