import { queryOptions, type QueryKey } from "@tanstack/react-query";
import type { ReferralEnabled } from "@warrenio/api-spec/spec.oats.gen";
import type { ApiClient } from "../../modules/api/apiClient";
import { getResponseData } from "../../modules/api/apiClient";
import { jsonEncodedBody } from "../../utils/fetchClient.ts";
import { adminMutation } from "../../utils/query/adminMutation.ts";
import { atomFromStandardQueryOptions } from "../../utils/query/queryAtom.ts";

export const queryKey: QueryKey = ["/payment/admin/referral_enabled"];

export function getQuery(client: ApiClient) {
    return queryOptions({
        queryKey,
        queryFn: async ({ signal }): Promise<ReferralEnabled> => {
            return getResponseData(await client.GET("/payment/admin/referral_enabled", { signal }));
        },
    });
}

export const referralEnabledQueryAtom = atomFromStandardQueryOptions(getQuery);

//#region Mutations

export const toggleEnabledMutation = adminMutation((apiClient) => ({
    entity: "referral_enabled",
    operation: "update",
    requestFn: async ({ enabled }: { enabled: boolean }) =>
        await apiClient.PUT("/payment/admin/referral_enabled", {
            ...jsonEncodedBody,
            body: { enabled: enabled },
        }),
    invalidateQueryKey: queryKey,
}));

//#endregion Mutations
