import { PriceMonthlyDetails } from "../../modules/pricing/PriceDetails.tsx";
import type { Price } from "../../modules/pricing/resourcePricing.ts";
import { DetailsTableRow } from "./DetailsTableRow.tsx";

interface MonthlyCostRowProps {
    price: Price;
}

export function MonthlyCostRow({ price }: MonthlyCostRowProps) {
    return (
        <DetailsTableRow title="Est. monthly cost:">
            <b>
                <PriceMonthlyDetails price={price} />
            </b>
        </DetailsTableRow>
    );
}
