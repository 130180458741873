import { apiUnixToDate } from "@warrenio/api-spec/conversion";
import type { Campaigns } from "@warrenio/api-spec/spec.oats.gen";
import { useState } from "react";
import { Badge } from "../../components/Badge.tsx";
import { WModalButton } from "../../components/button/WToolButton.tsx";
import { ClipBoardTooltip } from "../../components/ClipBoardTooltip.tsx";
import { WSearchField } from "../../components/forms/WSearchField.tsx";
import { CurrencyPromo } from "../../components/l10n/Currency.tsx";
import { LongDate } from "../../components/l10n/DateFormat.tsx";
import { DeleteModal } from "../../components/modal/DeleteModal.tsx";
import { WModal, WModalContent } from "../../components/modal/WModal.tsx";
import { WTable, WTableBody } from "../../components/table/WTable.tsx";
import { InfoTooltip } from "../../components/WTooltip.tsx";
import { TODO } from "../../dev/Todo.tsx";
import { todoAction } from "../../dev/todoSubmit.ts";
import { useStandardMutation } from "../../modules/api/useStandardMutation.ts";
import { getReferralUrl } from "../../modules/billing/referral/referralLogic.ts";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { AdminTitle } from "../AdminTitle.tsx";
import { AddCampaignModal } from "./AddCampaignModal.tsx";
import { campaignDeleteMutation, campaignsQueryAtom, campaignTypeMap } from "./campaignsQuery.ts";

export function CampaignsView() {
    const deleteMutation = useStandardMutation(campaignDeleteMutation);
    const campaigns = useSuspenseQueryAtom(campaignsQueryAtom);
    const [queryString, setQueryString] = useState("");

    async function onDelete({ id }: Campaigns) {
        await deleteMutation.mutateAsync({ id });
    }

    return (
        <>
            <AdminTitle title="Campaigns">
                <AddCampaignModal button={<WModalButton label="New" color="primary" size="bar" variant="basic" />} />
            </AdminTitle>

            <WTable adminTable search={<WSearchField value={queryString} onChange={setQueryString} />}>
                <thead>
                    <tr>
                        <th>Code</th>
                        <th>Url</th>
                        <th>Description</th>
                        <th className="text-right">Credit Amount</th>
                        <th>Type</th>
                        <th>Status</th>
                        <th>Created At</th>
                        <th className="text-right">
                            <div className="flex justify-end">
                                Used Count
                                <InfoTooltip
                                    className="ml-1"
                                    text="The count here reflects how many times the campaign has been successfully used. Results in billing account table may not always reflect as accurate usage statistics. Read more from documentation."
                                />
                            </div>
                        </th>
                        <th />
                    </tr>
                </thead>
                <WTableBody>
                    {campaigns.map((item) => (
                        <tr key={item.id}>
                            <td>
                                <ClipBoardTooltip>{item.campaign_code}</ClipBoardTooltip>
                            </td>
                            <td>
                                {item.campaign_type === "new_users" ? (
                                    <ClipBoardTooltip>{getReferralUrl(item.campaign_code)}</ClipBoardTooltip>
                                ) : (
                                    "-"
                                )}
                            </td>
                            <td>{item.campaign_description}</td>
                            <td className="text-right">
                                <CurrencyPromo value={item.credit_amount} />
                            </td>
                            <td>{campaignTypeMap[item.campaign_type]}</td>
                            <td>
                                <ActiveBadge value={item.is_active} />
                            </td>
                            <td>
                                <LongDate date={apiUnixToDate(item.created_at)} />
                            </td>
                            <td className="text-right">{item.used_count}</td>
                            <td className="text-right">
                                <div className="flex gap-0.5em justify-end">
                                    <WModal button={<WModalButton label="Edit" inTable icon="jp-icon-edit" />}>
                                        <WModalContent title="Edit Campaign" label="Edit" modalAction={todoAction}>
                                            <TODO />
                                        </WModalContent>
                                    </WModal>

                                    <DeleteModal
                                        title="Delete Campaign"
                                        inTable
                                        modalAction={async () => await onDelete(item)}
                                    >
                                        Are you sure you want to delete campaign "<b>{item.campaign_description}</b>"
                                        with code "<b>{item.campaign_code}</b>"?
                                    </DeleteModal>
                                </div>
                            </td>
                        </tr>
                    ))}
                </WTableBody>
            </WTable>

            <div className="p-2">
                <AddCampaignModal
                    button={
                        <WModalButton
                            className="w-full"
                            label="Add Campaign"
                            color="primary"
                            size="lg"
                            variant="dashed"
                            icon="jp-icon-add"
                        />
                    }
                />
            </div>
        </>
    );
}

function ActiveBadge({ value }: { value: boolean }) {
    return <Badge color={value ? "success" : "error"}>{value ? "Active" : "Inactive"}</Badge>;
}
