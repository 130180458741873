import { notNull } from "@warrenio/utils/notNull";
import { useAtomValue } from "jotai/react";
import { siteCurrencyAtom } from "../../config.ts";

export interface CurrencyFormat {
    symbol: string;
    name_plural: string;
    code: string;
    symbol_native: string;
    decimal_digits: number;
    name: string;
    rounding: number;
    decimal_digits_precise?: number;
    locale?: string;
    shorten?: boolean;
}

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions, @typescript-eslint/consistent-indexed-object-style
export type CurrencyFormats = {
    [currencySymbol: string]: CurrencyFormat;
};

export function useCurrencyFormat(currency: string) {
    return notNull(currencyFormats[currency]);
}

export function useSiteCurrencyFormat() {
    const siteCurrency = useAtomValue(siteCurrencyAtom);
    return useCurrencyFormat(siteCurrency);
}

export const currencyFormats: CurrencyFormats = {
    AED: {
        symbol: "AED",
        name_plural: "UAE dirhams",
        code: "AED",
        symbol_native: "د.إ.\u200F",
        decimal_digits: 2,
        name: "United Arab Emirates Dirham",
        rounding: 0.0,
        locale: "ar-ae",
    },
    AFN: {
        symbol: "AFN",
        name_plural: "Afghan Afghanis",
        code: "AFN",
        symbol_native: "؋",
        decimal_digits: 0,
        name: "Afghan Afghani",
        rounding: 0.0,
    },
    ALL: {
        symbol: "ALL",
        name_plural: "Albanian lekë",
        code: "ALL",
        symbol_native: "Lekë",
        decimal_digits: 0,
        name: "Albanian Lek",
        rounding: 0.0,
    },
    AMD: {
        symbol: "AMD",
        name_plural: "Armenian drams",
        code: "AMD",
        symbol_native: "֏",
        decimal_digits: 0,
        name: "Armenian Dram",
        rounding: 0.0,
    },
    ANG: {
        symbol: "ANG",
        name_plural: "Netherlands Antillean guilders",
        code: "ANG",
        symbol_native: "NAf.",
        decimal_digits: 2,
        name: "Netherlands Antillean Guilder",
        rounding: 0.0,
    },
    AOA: {
        symbol: "AOA",
        name_plural: "Angolan kwanzas",
        code: "AOA",
        symbol_native: "Kz",
        decimal_digits: 2,
        name: "Angolan Kwanza",
        rounding: 0.0,
    },
    ARS: {
        symbol: "ARS",
        name_plural: "Argentine pesos",
        code: "ARS",
        symbol_native: "$",
        decimal_digits: 2,
        name: "Argentine Peso",
        rounding: 0.0,
    },
    AUD: {
        symbol: "A$",
        name_plural: "Australian dollars",
        code: "AUD",
        symbol_native: "$",
        decimal_digits: 2,
        name: "Australian Dollar",
        rounding: 0.0,
    },
    AWG: {
        symbol: "AWG",
        name_plural: "Aruban florin",
        code: "AWG",
        symbol_native: "Afl.",
        decimal_digits: 2,
        name: "Aruban Florin",
        rounding: 0.0,
    },
    AZN: {
        symbol: "AZN",
        name_plural: "Azerbaijani manats",
        code: "AZN",
        symbol_native: "\u20BC",
        decimal_digits: 2,
        name: "Azerbaijani Manat",
        rounding: 0.0,
    },
    BAM: {
        symbol: "BAM",
        name_plural: "Bosnia-Herzegovina convertible marks",
        code: "BAM",
        symbol_native: "КМ",
        decimal_digits: 2,
        name: "Bosnia-Herzegovina Convertible Mark",
        rounding: 0.0,
    },
    BBD: {
        symbol: "BBD",
        name_plural: "Barbadian dollars",
        code: "BBD",
        symbol_native: "$",
        decimal_digits: 2,
        name: "Barbadian Dollar",
        rounding: 0.0,
    },
    BDT: {
        symbol: "BDT",
        name_plural: "Bangladeshi takas",
        code: "BDT",
        symbol_native: "৳",
        decimal_digits: 2,
        name: "Bangladeshi Taka",
        rounding: 0.0,
    },
    BGN: {
        symbol: "BGN",
        name_plural: "Bulgarian leva",
        code: "BGN",
        symbol_native: "лв.",
        decimal_digits: 2,
        name: "Bulgarian Lev",
        rounding: 0.0,
    },
    BHD: {
        symbol: "BHD",
        name_plural: "Bahraini dinars",
        code: "BHD",
        symbol_native: "د.ب.\u200F",
        decimal_digits: 3,
        name: "Bahraini Dinar",
        rounding: 0.0,
    },
    BIF: {
        symbol: "BIF",
        name_plural: "Burundian francs",
        code: "BIF",
        symbol_native: "FBu",
        decimal_digits: 0,
        name: "Burundian Franc",
        rounding: 0.0,
    },
    BMD: {
        symbol: "BMD",
        name_plural: "Bermudan dollars",
        code: "BMD",
        symbol_native: "$",
        decimal_digits: 2,
        name: "Bermudan Dollar",
        rounding: 0.0,
    },
    BND: {
        symbol: "BND",
        name_plural: "Brunei dollars",
        code: "BND",
        symbol_native: "$",
        decimal_digits: 2,
        name: "Brunei Dollar",
        rounding: 0.0,
    },
    BOB: {
        symbol: "BOB",
        name_plural: "Bolivian bolivianos",
        code: "BOB",
        symbol_native: "Bs",
        decimal_digits: 2,
        name: "Bolivian Boliviano",
        rounding: 0.0,
    },
    BOV: {
        symbol: "BOV",
        name_plural: "Bolivian mvdols",
        code: "BOV",
        symbol_native: "BOV",
        decimal_digits: 2,
        name: "Bolivian Mvdol",
        rounding: 0.0,
    },
    BRL: {
        symbol: "R$",
        name_plural: "Brazilian reals",
        code: "BRL",
        symbol_native: "R$",
        decimal_digits: 2,
        name: "Brazilian Real",
        rounding: 0.0,
        locale: "pt-br",
    },
    BSD: {
        symbol: "BSD",
        name_plural: "Bahamian dollars",
        code: "BSD",
        symbol_native: "$",
        decimal_digits: 2,
        name: "Bahamian Dollar",
        rounding: 0.0,
    },
    BTN: {
        symbol: "BTN",
        name_plural: "Bhutanese ngultrums",
        code: "BTN",
        symbol_native: "Nu.",
        decimal_digits: 2,
        name: "Bhutanese Ngultrum",
        rounding: 0.0,
    },
    BWP: {
        symbol: "BWP",
        name_plural: "Botswanan pulas",
        code: "BWP",
        symbol_native: "P",
        decimal_digits: 2,
        name: "Botswanan Pula",
        rounding: 0.0,
    },
    BYN: {
        symbol: "BYN",
        name_plural: "Belarusian rubles",
        code: "BYN",
        symbol_native: "Br",
        decimal_digits: 2,
        name: "Belarusian Ruble",
        rounding: 0.0,
    },
    BZD: {
        symbol: "BZD",
        name_plural: "Belize dollars",
        code: "BZD",
        symbol_native: "$",
        decimal_digits: 2,
        name: "Belize Dollar",
        rounding: 0.0,
    },
    CAD: {
        symbol: "CA$",
        name_plural: "Canadian dollars",
        code: "CAD",
        symbol_native: "$",
        decimal_digits: 2,
        name: "Canadian Dollar",
        rounding: 0.0,
    },
    CDF: {
        symbol: "CDF",
        name_plural: "Congolese francs",
        code: "CDF",
        symbol_native: "FC",
        decimal_digits: 2,
        name: "Congolese Franc",
        rounding: 0.0,
    },
    CHE: {
        symbol: "CHE",
        name_plural: "WIR euros",
        code: "CHE",
        symbol_native: "CHE",
        decimal_digits: 2,
        name: "WIR Euro",
        rounding: 0.0,
    },
    CHF: {
        symbol: "CHF",
        name_plural: "Swiss francs",
        code: "CHF",
        symbol_native: "CHF",
        decimal_digits: 2,
        name: "Swiss Franc",
        rounding: 0.0,
    },
    CHW: {
        symbol: "CHW",
        name_plural: "WIR francs",
        code: "CHW",
        symbol_native: "CHW",
        decimal_digits: 2,
        name: "WIR Franc",
        rounding: 0.0,
    },
    CLF: {
        symbol: "CLF",
        name_plural: "Chilean units of account (UF)",
        code: "CLF",
        symbol_native: "CLF",
        decimal_digits: 4,
        name: "Chilean Unit of Account (UF)",
        rounding: 0.0,
    },
    CLP: {
        symbol: "CLP",
        name_plural: "Chilean pesos",
        code: "CLP",
        symbol_native: "$",
        decimal_digits: 0,
        name: "Chilean Peso",
        rounding: 0.0,
    },
    CNH: {
        symbol: "CNH",
        name_plural: "Chinese yuan (offshore)",
        code: "CNH",
        symbol_native: "CNH",
        decimal_digits: 2,
        name: "Chinese Yuan (offshore)",
        rounding: 0.0,
    },
    CNY: {
        symbol: "CN¥",
        name_plural: "Chinese yuan",
        code: "CNY",
        symbol_native: "¥",
        decimal_digits: 2,
        name: "Chinese Yuan",
        rounding: 0.0,
    },
    COP: {
        symbol: "COP",
        name_plural: "Colombian pesos",
        code: "COP",
        symbol_native: "$",
        decimal_digits: 0,
        name: "Colombian Peso",
        rounding: 0.0,
    },
    COU: {
        symbol: "COU",
        name_plural: "Colombian real value units",
        code: "COU",
        symbol_native: "COU",
        decimal_digits: 2,
        name: "Colombian Real Value Unit",
        rounding: 0.0,
    },
    CRC: {
        symbol: "CRC",
        name_plural: "Costa Rican colóns",
        code: "CRC",
        symbol_native: "\u20A1",
        decimal_digits: 2,
        name: "Costa Rican Colón",
        rounding: 0.0,
    },
    CUC: {
        symbol: "CUC",
        name_plural: "Cuban convertible pesos",
        code: "CUC",
        symbol_native: "CUC",
        decimal_digits: 2,
        name: "Cuban Convertible Peso",
        rounding: 0.0,
    },
    CUP: {
        symbol: "CUP",
        name_plural: "Cuban pesos",
        code: "CUP",
        symbol_native: "$",
        decimal_digits: 2,
        name: "Cuban Peso",
        rounding: 0.0,
    },
    CVE: {
        symbol: "CVE",
        name_plural: "Cape Verdean escudos",
        code: "CVE",
        symbol_native: "\u200B",
        decimal_digits: 2,
        name: "Cape Verdean Escudo",
        rounding: 0.0,
    },
    CZK: {
        symbol: "CZK",
        name_plural: "Czech korunas",
        code: "CZK",
        symbol_native: "Kč",
        decimal_digits: 2,
        name: "Czech Koruna",
        rounding: 0.0,
    },
    DJF: {
        symbol: "DJF",
        name_plural: "Djiboutian francs",
        code: "DJF",
        symbol_native: "Fdj",
        decimal_digits: 0,
        name: "Djiboutian Franc",
        rounding: 0.0,
    },
    DKK: {
        symbol: "DKK",
        name_plural: "Danish kroner",
        code: "DKK",
        symbol_native: "kr.",
        decimal_digits: 2,
        name: "Danish Krone",
        rounding: 0.0,
    },
    DOP: {
        symbol: "DOP",
        name_plural: "Dominican pesos",
        code: "DOP",
        symbol_native: "RD$",
        decimal_digits: 2,
        name: "Dominican Peso",
        rounding: 0.0,
    },
    DZD: {
        symbol: "DZD",
        name_plural: "Algerian dinars",
        code: "DZD",
        symbol_native: "د.ج.\u200F",
        decimal_digits: 2,
        name: "Algerian Dinar",
        rounding: 0.0,
    },
    EGP: {
        symbol: "EGP",
        name_plural: "Egyptian pounds",
        code: "EGP",
        symbol_native: "ج.م.\u200F",
        decimal_digits: 2,
        name: "Egyptian Pound",
        rounding: 0.0,
    },
    ERN: {
        symbol: "ERN",
        name_plural: "Eritrean nakfas",
        code: "ERN",
        symbol_native: "Nfk",
        decimal_digits: 2,
        name: "Eritrean Nakfa",
        rounding: 0.0,
    },
    ETB: {
        symbol: "ETB",
        name_plural: "Ethiopian birrs",
        code: "ETB",
        symbol_native: "ብር",
        decimal_digits: 2,
        name: "Ethiopian Birr",
        rounding: 0.0,
    },
    EUR: {
        symbol: "\u20AC",
        name_plural: "euros",
        code: "EUR",
        symbol_native: "\u20AC",
        decimal_digits: 2,
        name: "Euro",
        rounding: 0.0,
        locale: "et-EE",
    },
    FJD: {
        symbol: "FJD",
        name_plural: "Fijian dollars",
        code: "FJD",
        symbol_native: "$",
        decimal_digits: 2,
        name: "Fijian Dollar",
        rounding: 0.0,
    },
    FKP: {
        symbol: "FKP",
        name_plural: "Falkland Islands pounds",
        code: "FKP",
        symbol_native: "£",
        decimal_digits: 2,
        name: "Falkland Islands Pound",
        rounding: 0.0,
    },
    GBP: {
        symbol: "£",
        name_plural: "British pounds",
        code: "GBP",
        symbol_native: "£",
        decimal_digits: 2,
        name: "British Pound",
        rounding: 0.0,
        locale: "en-gb",
    },
    GEL: {
        symbol: "GEL",
        name_plural: "Georgian laris",
        code: "GEL",
        symbol_native: "\u20BE",
        decimal_digits: 2,
        name: "Georgian Lari",
        rounding: 0.0,
    },
    GHS: {
        symbol: "GHS",
        name_plural: "Ghanaian cedis",
        code: "GHS",
        symbol_native: "GH\u20B5",
        decimal_digits: 2,
        name: "Ghanaian Cedi",
        rounding: 0.0,
    },
    GIP: {
        symbol: "GIP",
        name_plural: "Gibraltar pounds",
        code: "GIP",
        symbol_native: "£",
        decimal_digits: 2,
        name: "Gibraltar Pound",
        rounding: 0.0,
    },
    GMD: {
        symbol: "GMD",
        name_plural: "Gambian dalasis",
        code: "GMD",
        symbol_native: "D",
        decimal_digits: 2,
        name: "Gambian Dalasi",
        rounding: 0.0,
    },
    GNF: {
        symbol: "GNF",
        name_plural: "Guinean francs",
        code: "GNF",
        symbol_native: "FG",
        decimal_digits: 0,
        name: "Guinean Franc",
        rounding: 0.0,
    },
    GTQ: {
        symbol: "GTQ",
        name_plural: "Guatemalan quetzals",
        code: "GTQ",
        symbol_native: "Q",
        decimal_digits: 2,
        name: "Guatemalan Quetzal",
        rounding: 0.0,
    },
    GYD: {
        symbol: "GYD",
        name_plural: "Guyanaese dollars",
        code: "GYD",
        symbol_native: "$",
        decimal_digits: 0,
        name: "Guyanaese Dollar",
        rounding: 0.0,
    },
    HKD: {
        symbol: "HK$",
        name_plural: "Hong Kong dollars",
        code: "HKD",
        symbol_native: "HK$",
        decimal_digits: 2,
        name: "Hong Kong Dollar",
        rounding: 0.0,
    },
    HNL: {
        symbol: "HNL",
        name_plural: "Honduran lempiras",
        code: "HNL",
        symbol_native: "L",
        decimal_digits: 2,
        name: "Honduran Lempira",
        rounding: 0.0,
    },
    HRK: {
        symbol: "HRK",
        name_plural: "Croatian kunas",
        code: "HRK",
        symbol_native: "HRK",
        decimal_digits: 2,
        name: "Croatian Kuna",
        rounding: 0.0,
    },
    HTG: {
        symbol: "HTG",
        name_plural: "Haitian gourdes",
        code: "HTG",
        symbol_native: "G",
        decimal_digits: 2,
        name: "Haitian Gourde",
        rounding: 0.0,
    },
    HUF: {
        symbol: "HUF",
        name_plural: "Hungarian forints",
        code: "HUF",
        symbol_native: "Ft",
        decimal_digits: 2,
        name: "Hungarian Forint",
        rounding: 0.0,
    },
    IDR: {
        symbol: "IDR",
        name_plural: "Indonesian rupiahs",
        code: "IDR",
        symbol_native: "Rp",
        decimal_digits: 0,
        decimal_digits_precise: 2,
        name: "Indonesian Rupiah",
        rounding: 0.0,
        locale: "id",
        shorten: true,
    },
    ILS: {
        symbol: "\u20AA",
        name_plural: "Israeli new shekels",
        code: "ILS",
        symbol_native: "\u20AA",
        decimal_digits: 2,
        name: "Israeli New Shekel",
        rounding: 0.0,
    },
    INR: {
        symbol: "\u20B9",
        name_plural: "Indian rupees",
        code: "INR",
        symbol_native: "\u20B9",
        decimal_digits: 2,
        name: "Indian Rupee",
        rounding: 0.0,
        locale: "pa-in",
    },
    IQD: {
        symbol: "IQD",
        name_plural: "Iraqi dinars",
        code: "IQD",
        symbol_native: "د.ع.\u200F",
        decimal_digits: 0,
        name: "Iraqi Dinar",
        rounding: 0.0,
    },
    IRR: {
        symbol: "IRR",
        name_plural: "Iranian rials",
        code: "IRR",
        symbol_native: "IRR",
        decimal_digits: 0,
        name: "Iranian Rial",
        rounding: 0.0,
    },
    ISK: {
        symbol: "ISK",
        name_plural: "Icelandic krónur",
        code: "ISK",
        symbol_native: "ISK",
        decimal_digits: 0,
        name: "Icelandic Króna",
        rounding: 0.0,
    },
    JMD: {
        symbol: "JMD",
        name_plural: "Jamaican dollars",
        code: "JMD",
        symbol_native: "$",
        decimal_digits: 2,
        name: "Jamaican Dollar",
        rounding: 0.0,
    },
    JOD: {
        symbol: "JOD",
        name_plural: "Jordanian dinars",
        code: "JOD",
        symbol_native: "د.أ.\u200F",
        decimal_digits: 3,
        name: "Jordanian Dinar",
        rounding: 0.0,
    },
    JPY: {
        symbol: "¥",
        name_plural: "Japanese yen",
        code: "JPY",
        symbol_native: "￥",
        decimal_digits: 0,
        name: "Japanese Yen",
        rounding: 0.0,
    },
    KES: {
        symbol: "KES",
        name_plural: "Kenyan shillings",
        code: "KES",
        symbol_native: "Ksh",
        decimal_digits: 2,
        name: "Kenyan Shilling",
        rounding: 0.0,
    },
    KGS: {
        symbol: "KGS",
        name_plural: "Kyrgystani soms",
        code: "KGS",
        symbol_native: "сом",
        decimal_digits: 2,
        name: "Kyrgystani Som",
        rounding: 0.0,
    },
    KHR: {
        symbol: "KHR",
        name_plural: "Cambodian riels",
        code: "KHR",
        symbol_native: "៛",
        decimal_digits: 2,
        name: "Cambodian Riel",
        rounding: 0.0,
    },
    KMF: {
        symbol: "KMF",
        name_plural: "Comorian francs",
        code: "KMF",
        symbol_native: "CF",
        decimal_digits: 0,
        name: "Comorian Franc",
        rounding: 0.0,
    },
    KPW: {
        symbol: "KPW",
        name_plural: "North Korean won",
        code: "KPW",
        symbol_native: "KPW",
        decimal_digits: 0,
        name: "North Korean Won",
        rounding: 0.0,
    },
    KRW: {
        symbol: "\u20A9",
        name_plural: "South Korean won",
        code: "KRW",
        symbol_native: "\u20A9",
        decimal_digits: 0,
        name: "South Korean Won",
        rounding: 0.0,
    },
    KWD: {
        symbol: "KWD",
        name_plural: "Kuwaiti dinars",
        code: "KWD",
        symbol_native: "د.ك.\u200F",
        decimal_digits: 3,
        name: "Kuwaiti Dinar",
        rounding: 0.0,
    },
    KYD: {
        symbol: "KYD",
        name_plural: "Cayman Islands dollars",
        code: "KYD",
        symbol_native: "$",
        decimal_digits: 2,
        name: "Cayman Islands Dollar",
        rounding: 0.0,
    },
    KZT: {
        symbol: "KZT",
        name_plural: "Kazakhstani tenges",
        code: "KZT",
        symbol_native: "\u20B8",
        decimal_digits: 2,
        name: "Kazakhstani Tenge",
        rounding: 0.0,
    },
    LAK: {
        symbol: "LAK",
        name_plural: "Laotian kips",
        code: "LAK",
        symbol_native: "\u20AD",
        decimal_digits: 0,
        name: "Laotian Kip",
        rounding: 0.0,
    },
    LBP: {
        symbol: "LBP",
        name_plural: "Lebanese pounds",
        code: "LBP",
        symbol_native: "ل.ل.\u200F",
        decimal_digits: 0,
        name: "Lebanese Pound",
        rounding: 0.0,
    },
    LKR: {
        symbol: "LKR",
        name_plural: "Sri Lankan rupees",
        code: "LKR",
        symbol_native: "රු.",
        decimal_digits: 2,
        name: "Sri Lankan Rupee",
        rounding: 0.0,
    },
    LRD: {
        symbol: "LRD",
        name_plural: "Liberian dollars",
        code: "LRD",
        symbol_native: "$",
        decimal_digits: 2,
        name: "Liberian Dollar",
        rounding: 0.0,
    },
    LSL: {
        symbol: "LSL",
        name_plural: "Lesotho lotis",
        code: "LSL",
        symbol_native: "LSL",
        decimal_digits: 2,
        name: "Lesotho Loti",
        rounding: 0.0,
    },
    LYD: {
        symbol: "LYD",
        name_plural: "Libyan dinars",
        code: "LYD",
        symbol_native: "د.ل.\u200F",
        decimal_digits: 3,
        name: "Libyan Dinar",
        rounding: 0.0,
    },
    MAD: {
        symbol: "MAD",
        name_plural: "Moroccan dirhams",
        code: "MAD",
        symbol_native: "د.م.\u200F",
        decimal_digits: 2,
        name: "Moroccan Dirham",
        rounding: 0.0,
    },
    MDL: {
        symbol: "MDL",
        name_plural: "Moldovan lei",
        code: "MDL",
        symbol_native: "L",
        decimal_digits: 2,
        name: "Moldovan Leu",
        rounding: 0.0,
    },
    MGA: {
        symbol: "MGA",
        name_plural: "Malagasy ariaries",
        code: "MGA",
        symbol_native: "Ar",
        decimal_digits: 0,
        name: "Malagasy Ariary",
        rounding: 0.0,
    },
    MKD: {
        symbol: "MKD",
        name_plural: "Macedonian denari",
        code: "MKD",
        symbol_native: "ден",
        decimal_digits: 2,
        name: "Macedonian Denar",
        rounding: 0.0,
    },
    MMK: {
        symbol: "MMK",
        name_plural: "Myanmar kyats",
        code: "MMK",
        symbol_native: "K",
        decimal_digits: 0,
        name: "Myanmar Kyat",
        rounding: 0.0,
    },
    MNT: {
        symbol: "MNT",
        name_plural: "Mongolian tugriks",
        code: "MNT",
        symbol_native: "\u20AE",
        decimal_digits: 0,
        name: "Mongolian Tugrik",
        rounding: 0.0,
    },
    MOP: {
        symbol: "MOP",
        name_plural: "Macanese patacas",
        code: "MOP",
        symbol_native: "MOP$",
        decimal_digits: 2,
        name: "Macanese Pataca",
        rounding: 0.0,
    },
    MRO: {
        symbol: "MRO",
        name_plural: "Mauritanian ouguiyas",
        code: "MRO",
        symbol_native: "أ.م.\u200F",
        decimal_digits: 0,
        name: "Mauritanian Ouguiya",
        rounding: 0.0,
    },
    MUR: {
        symbol: "MUR",
        name_plural: "Mauritian rupees",
        code: "MUR",
        symbol_native: "Rs",
        decimal_digits: 0,
        name: "Mauritian Rupee",
        rounding: 0.0,
    },
    MWK: {
        symbol: "MWK",
        name_plural: "Malawian kwachas",
        code: "MWK",
        symbol_native: "MK",
        decimal_digits: 2,
        name: "Malawian Kwacha",
        rounding: 0.0,
    },
    MXN: {
        symbol: "MX$",
        name_plural: "Mexican pesos",
        code: "MXN",
        symbol_native: "$",
        decimal_digits: 2,
        name: "Mexican Peso",
        rounding: 0.0,
    },
    MXV: {
        symbol: "MXV",
        name_plural: "Mexican investment units",
        code: "MXV",
        symbol_native: "MXV",
        decimal_digits: 2,
        name: "Mexican Investment Unit",
        rounding: 0.0,
    },
    MYR: {
        symbol: "MYR",
        name_plural: "Malaysian ringgits",
        code: "MYR",
        symbol_native: "RM",
        decimal_digits: 2,
        name: "Malaysian Ringgit",
        rounding: 0.0,
    },
    MZN: {
        symbol: "MZN",
        name_plural: "Mozambican meticals",
        code: "MZN",
        symbol_native: "MTn",
        decimal_digits: 2,
        name: "Mozambican Metical",
        rounding: 0.0,
    },
    NAD: {
        symbol: "NAD",
        name_plural: "Namibian dollars",
        code: "NAD",
        symbol_native: "$",
        decimal_digits: 2,
        name: "Namibian Dollar",
        rounding: 0.0,
    },
    NGN: {
        symbol: "NGN",
        name_plural: "Nigerian nairas",
        code: "NGN",
        symbol_native: "\u20A6",
        decimal_digits: 2,
        name: "Nigerian Naira",
        rounding: 0.0,
    },
    NIO: {
        symbol: "NIO",
        name_plural: "Nicaraguan córdobas",
        code: "NIO",
        symbol_native: "C$",
        decimal_digits: 2,
        name: "Nicaraguan Córdoba",
        rounding: 0.0,
    },
    NOK: {
        symbol: "NOK",
        name_plural: "Norwegian kroner",
        code: "NOK",
        symbol_native: "kr",
        decimal_digits: 2,
        name: "Norwegian Krone",
        rounding: 0.0,
    },
    NPR: {
        symbol: "NPR",
        name_plural: "Nepalese rupees",
        code: "NPR",
        symbol_native: "नेरू",
        decimal_digits: 2,
        name: "Nepalese Rupee",
        rounding: 0.0,
    },
    NZD: {
        symbol: "NZ$",
        name_plural: "New Zealand dollars",
        code: "NZD",
        symbol_native: "$",
        decimal_digits: 2,
        name: "New Zealand Dollar",
        rounding: 0.0,
    },
    OMR: {
        symbol: "OMR",
        name_plural: "Omani rials",
        code: "OMR",
        symbol_native: "ر.ع.\u200F",
        decimal_digits: 3,
        name: "Omani Rial",
        rounding: 0.0,
    },
    PAB: {
        symbol: "PAB",
        name_plural: "Panamanian balboas",
        code: "PAB",
        symbol_native: "B/.",
        decimal_digits: 2,
        name: "Panamanian Balboa",
        rounding: 0.0,
    },
    PEN: {
        symbol: "PEN",
        name_plural: "Peruvian soles",
        code: "PEN",
        symbol_native: "S/",
        decimal_digits: 2,
        name: "Peruvian Sol",
        rounding: 0.0,
    },
    PGK: {
        symbol: "PGK",
        name_plural: "Papua New Guinean kina",
        code: "PGK",
        symbol_native: "K",
        decimal_digits: 2,
        name: "Papua New Guinean Kina",
        rounding: 0.0,
    },
    PHP: {
        symbol: "PHP",
        name_plural: "Philippine pisos",
        code: "PHP",
        symbol_native: "\u20B1",
        decimal_digits: 2,
        name: "Philippine Piso",
        rounding: 0.0,
    },
    PKR: {
        symbol: "PKR",
        name_plural: "Pakistani rupees",
        code: "PKR",
        symbol_native: "Rs",
        decimal_digits: 0,
        name: "Pakistani Rupee",
        rounding: 0.0,
    },
    PLN: {
        symbol: "PLN",
        name_plural: "Polish zlotys",
        code: "PLN",
        symbol_native: "zł",
        decimal_digits: 2,
        name: "Polish Zloty",
        rounding: 0.0,
    },
    PYG: {
        symbol: "PYG",
        name_plural: "Paraguayan guaranis",
        code: "PYG",
        symbol_native: "Gs.",
        decimal_digits: 0,
        name: "Paraguayan Guarani",
        rounding: 0.0,
    },
    QAR: {
        symbol: "QAR",
        name_plural: "Qatari rials",
        code: "QAR",
        symbol_native: "ر.ق.\u200F",
        decimal_digits: 2,
        name: "Qatari Rial",
        rounding: 0.0,
    },
    RON: {
        symbol: "RON",
        name_plural: "Romanian lei",
        code: "RON",
        symbol_native: "RON",
        decimal_digits: 2,
        name: "Romanian Leu",
        rounding: 0.0,
    },
    RSD: {
        symbol: "RSD",
        name_plural: "Serbian dinars",
        code: "RSD",
        symbol_native: "RSD",
        decimal_digits: 0,
        name: "Serbian Dinar",
        rounding: 0.0,
    },
    RUB: {
        symbol: "RUB",
        name_plural: "Russian rubles",
        code: "RUB",
        symbol_native: "\u20BD",
        decimal_digits: 2,
        name: "Russian Ruble",
        rounding: 0.0,
        locale: "ru",
    },
    RWF: {
        symbol: "RWF",
        name_plural: "Rwandan francs",
        code: "RWF",
        symbol_native: "RF",
        decimal_digits: 0,
        name: "Rwandan Franc",
        rounding: 0.0,
    },
    SAR: {
        symbol: "SAR",
        name_plural: "Saudi riyals",
        code: "SAR",
        symbol_native: "ر.س.\u200F",
        decimal_digits: 2,
        name: "Saudi Riyal",
        rounding: 0.0,
    },
    SBD: {
        symbol: "SBD",
        name_plural: "Solomon Islands dollars",
        code: "SBD",
        symbol_native: "$",
        decimal_digits: 2,
        name: "Solomon Islands Dollar",
        rounding: 0.0,
    },
    SCR: {
        symbol: "SCR",
        name_plural: "Seychellois rupees",
        code: "SCR",
        symbol_native: "SR",
        decimal_digits: 2,
        name: "Seychellois Rupee",
        rounding: 0.0,
    },
    SDG: {
        symbol: "SDG",
        name_plural: "Sudanese pounds",
        code: "SDG",
        symbol_native: "ج.س.",
        decimal_digits: 2,
        name: "Sudanese Pound",
        rounding: 0.0,
    },
    SEK: {
        symbol: "SEK",
        name_plural: "Swedish kronor",
        code: "SEK",
        symbol_native: "kr",
        decimal_digits: 2,
        name: "Swedish Krona",
        rounding: 0.0,
    },
    SGD: {
        symbol: "SGD",
        name_plural: "Singapore dollars",
        code: "SGD",
        symbol_native: "$",
        decimal_digits: 2,
        name: "Singapore Dollar",
        rounding: 0.0,
    },
    SHP: {
        symbol: "SHP",
        name_plural: "St. Helena pounds",
        code: "SHP",
        symbol_native: "£",
        decimal_digits: 2,
        name: "St. Helena Pound",
        rounding: 0.0,
    },
    SLL: {
        symbol: "SLL",
        name_plural: "Sierra Leonean leones",
        code: "SLL",
        symbol_native: "Le",
        decimal_digits: 0,
        name: "Sierra Leonean Leone",
        rounding: 0.0,
    },
    SOS: {
        symbol: "SOS",
        name_plural: "Somali shillings",
        code: "SOS",
        symbol_native: "S",
        decimal_digits: 0,
        name: "Somali Shilling",
        rounding: 0.0,
    },
    SRD: {
        symbol: "SRD",
        name_plural: "Surinamese dollars",
        code: "SRD",
        symbol_native: "$",
        decimal_digits: 2,
        name: "Surinamese Dollar",
        rounding: 0.0,
    },
    SSP: {
        symbol: "SSP",
        name_plural: "South Sudanese pounds",
        code: "SSP",
        symbol_native: "£",
        decimal_digits: 2,
        name: "South Sudanese Pound",
        rounding: 0.0,
    },
    STN: {
        symbol: "STN",
        name_plural: "São Tomé & Príncipe dobras (2018)",
        code: "STN",
        symbol_native: "STN",
        decimal_digits: 2,
        name: "São Tomé & Príncipe Dobra (2018)",
        rounding: 0.0,
    },
    SYP: {
        symbol: "SYP",
        name_plural: "Syrian pounds",
        code: "SYP",
        symbol_native: "ل.س.\u200F",
        decimal_digits: 0,
        name: "Syrian Pound",
        rounding: 0.0,
    },
    SZL: {
        symbol: "SZL",
        name_plural: "Swazi emalangeni",
        code: "SZL",
        symbol_native: "E",
        decimal_digits: 2,
        name: "Swazi Lilangeni",
        rounding: 0.0,
    },
    THB: {
        symbol: "THB",
        name_plural: "Thai baht",
        code: "THB",
        symbol_native: "THB",
        decimal_digits: 2,
        name: "Thai Baht",
        rounding: 0.0,
    },
    TJS: {
        symbol: "TJS",
        name_plural: "Tajikistani somonis",
        code: "TJS",
        symbol_native: "сом.",
        decimal_digits: 2,
        name: "Tajikistani Somoni",
        rounding: 0.0,
    },
    TND: {
        symbol: "TND",
        name_plural: "Tunisian dinars",
        code: "TND",
        symbol_native: "د.ت.\u200F",
        decimal_digits: 3,
        name: "Tunisian Dinar",
        rounding: 0.0,
    },
    TOP: {
        symbol: "TOP",
        name_plural: "Tongan paʻanga",
        code: "TOP",
        symbol_native: "T$",
        decimal_digits: 2,
        name: "Tongan Paʻanga",
        rounding: 0.0,
    },
    TRY: {
        symbol: "TRY",
        name_plural: "Turkish Lira",
        code: "TRY",
        symbol_native: "\u20BA",
        decimal_digits: 2,
        name: "Turkish Lira",
        rounding: 0.0,
    },
    TTD: {
        symbol: "TTD",
        name_plural: "Trinidad & Tobago dollars",
        code: "TTD",
        symbol_native: "$",
        decimal_digits: 2,
        name: "Trinidad & Tobago Dollar",
        rounding: 0.0,
    },
    TWD: {
        symbol: "NT$",
        name_plural: "New Taiwan dollars",
        code: "TWD",
        symbol_native: "$",
        decimal_digits: 2,
        name: "New Taiwan Dollar",
        rounding: 0.0,
    },
    TZS: {
        symbol: "TZS",
        name_plural: "Tanzanian shillings",
        code: "TZS",
        symbol_native: "TSh",
        decimal_digits: 0,
        name: "Tanzanian Shilling",
        rounding: 0.0,
    },
    UAH: {
        symbol: "UAH",
        name_plural: "Ukrainian hryvnias",
        code: "UAH",
        symbol_native: "\u20B4",
        decimal_digits: 2,
        name: "Ukrainian Hryvnia",
        rounding: 0.0,
    },
    UGX: {
        symbol: "UGX",
        name_plural: "Ugandan shillings",
        code: "UGX",
        symbol_native: "USh",
        decimal_digits: 0,
        name: "Ugandan Shilling",
        rounding: 0.0,
    },
    USD: {
        symbol: "$",
        name_plural: "US dollars",
        code: "USD",
        symbol_native: "$",
        decimal_digits: 2,
        name: "US Dollar",
        rounding: 0.0,
        locale: "en-us",
    },
    USN: {
        symbol: "USN",
        name_plural: "US dollars (next day)",
        code: "USN",
        symbol_native: "USN",
        decimal_digits: 2,
        name: "US Dollar (Next day)",
        rounding: 0.0,
    },
    UYI: {
        symbol: "UYI",
        name_plural: "Uruguayan pesos (indexed units)",
        code: "UYI",
        symbol_native: "UYI",
        decimal_digits: 0,
        name: "Uruguayan Peso (Indexed Units)",
        rounding: 0.0,
    },
    UYU: {
        symbol: "UYU",
        name_plural: "Uruguayan pesos",
        code: "UYU",
        symbol_native: "$",
        decimal_digits: 2,
        name: "Uruguayan Peso",
        rounding: 0.0,
    },
    UZS: {
        symbol: "UZS",
        name_plural: "Uzbekistani som",
        code: "UZS",
        symbol_native: "сўм",
        decimal_digits: 0,
        name: "Uzbekistani Som",
        rounding: 0.0,
    },
    VEF: {
        symbol: "VEF",
        name_plural: "Venezuelan bolívars",
        code: "VEF",
        symbol_native: "Bs.",
        decimal_digits: 2,
        name: "Venezuelan Bolívar",
        rounding: 0.0,
    },
    VND: {
        symbol: "\u20AB",
        name_plural: "Vietnamese dong",
        code: "VND",
        symbol_native: "\u20AB",
        decimal_digits: 0,
        name: "Vietnamese Dong",
        rounding: 0.0,
    },
    VUV: {
        symbol: "VUV",
        name_plural: "Vanuatu vatus",
        code: "VUV",
        symbol_native: "VT",
        decimal_digits: 0,
        name: "Vanuatu Vatu",
        rounding: 0.0,
    },
    WST: {
        symbol: "WST",
        name_plural: "Samoan tala",
        code: "WST",
        symbol_native: "WS$",
        decimal_digits: 2,
        name: "Samoan Tala",
        rounding: 0.0,
    },
    XAF: {
        symbol: "FCFA",
        name_plural: "Central African CFA francs",
        code: "XAF",
        symbol_native: "FCFA",
        decimal_digits: 0,
        name: "Central African CFA Franc",
        rounding: 0.0,
    },
    XCD: {
        symbol: "EC$",
        name_plural: "East Caribbean dollars",
        code: "XCD",
        symbol_native: "$",
        decimal_digits: 2,
        name: "East Caribbean Dollar",
        rounding: 0.0,
    },
    XOF: {
        symbol: "CFA",
        name_plural: "West African CFA francs",
        code: "XOF",
        symbol_native: "CFA",
        decimal_digits: 0,
        name: "West African CFA Franc",
        rounding: 0.0,
    },
    XPF: {
        symbol: "CFPF",
        name_plural: "CFP francs",
        code: "XPF",
        symbol_native: "FCFP",
        decimal_digits: 0,
        name: "CFP Franc",
        rounding: 0.0,
    },
    YER: {
        symbol: "YER",
        name_plural: "Yemeni rials",
        code: "YER",
        symbol_native: "ر.ي.\u200F",
        decimal_digits: 0,
        name: "Yemeni Rial",
        rounding: 0.0,
    },
    ZAR: {
        symbol: "ZAR",
        name_plural: "South African rand",
        code: "ZAR",
        symbol_native: "R",
        decimal_digits: 2,
        name: "South African Rand",
        rounding: 0.0,
    },
    ZMW: {
        symbol: "ZMW",
        name_plural: "Zambian kwachas",
        code: "ZMW",
        symbol_native: "K",
        decimal_digits: 2,
        name: "Zambian Kwacha",
        rounding: 0.0,
    },
};
